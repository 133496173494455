/**
 * AWS S3
 */

import AWS from 'aws-sdk'
import { platformApi } from './api'

/**
 * 上传文件到S3
 * @param {object} file 要上传的文件 (File 对象)
 * @param {string} path 要将文件保存的相对路径 (如: 'images/avatar')
 * @param {function} onProgress 上传进度的回调函数
 */
export async function uploadFile (file, path, onProgress) {
  const credential = await platformApi.getCredentials()
  if (credential.httpStatus === 200) {
    AWS.config.update({
      accessKeyId: credential.data.accessKeyId,
      secretAccessKey: credential.data.secretAccessKey,
      sessionToken: credential.data.sessionToken,
      region: 'ap-southeast-1'
    })

    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: 'newbee-forum-singapore',
        Key: `${path}/${file.name}`,
        Body: file.raw ? file.raw : file,
        ACL: 'public-read'
      }
    })

    return new Promise((resolve, reject) => {
      upload.on('httpUploadProgress', (evt) => {
        if (typeof onProgress === 'function') {
          onProgress(evt.loaded, evt.total)
        }
      })

      upload.send((err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }
  return Promise.reject(new Error('获取S3凭证失败'))
}
